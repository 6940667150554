/**
 * DateRangeInput wraps DateRangePicker from React-dates and gives a list of all default props we use.
 * Styles for DateRangePicker can be found from 'public/reactDates.css'.
 *
 * N.B. *isOutsideRange* in defaultProps is defining what dates are available to booking.
 */
import React, { Component } from 'react';
import { bool, func, instanceOf, oneOf, shape, string, any } from 'prop-types';
import { DateRangePicker, SingleDatePicker } from 'react-dates';
import classNames from 'classnames';
import moment from 'moment';

import { intlShape, injectIntl, FormattedMessage } from '../../util/reactIntl';
import { START_DATE, END_DATE } from '../../util/dates';

import { IconArrowHead } from '../../components';
import css from './DateRangeInput.module.scss';
import { LINE_ITEM_MONTH, LINE_ITEM_DAY } from '../../util/types';
import { debounce } from 'debounce';
import { speculateTransaction } from '../../containers/CheckoutPage/CheckoutPage.duck';
import { compose } from 'redux';
import { connect } from 'react-redux';

export const HORIZONTAL_ORIENTATION = 'horizontal';
export const ANCHOR_LEFT = 'left';

// When the unit type is day, the endDate of booking range is exclusive.
// In the UI picker, we show only inclusive dates
const apiEndDateToPickerDate = (isDaily, endDate) => {
  const isValid = endDate instanceof Date;

  // API end dates are exlusive, so we need to shift them with daily
  // booking.
  return isValid && isDaily
    ? moment(endDate).subtract(1, 'days')
    : isValid
      ? moment(endDate)
      : null;
};

// When the unit type is day, the endDate of booking range is exclusive.
// In the UI picker, we show only inclusive dates
const pickerEndDateToApiDate = (unitType, endDate) => {
  // const isValid = endDate instanceof moment;

  // // API end dates are exlusive, so we need to shift them with daily
  // // booking.
  // return isValid && isDaily
  //   ? endDate
  //     .clone()
  //     .add(1, 'days')
  //     .toDate()
  //   : isValid
  //     ? endDate.toDate()
  //     : null;
  const isValid = endDate instanceof moment;
  const isDaily = unitType === LINE_ITEM_DAY;

  if (!isValid) {
    return null;
  } else if (isDaily) {
    // API end dates are exlusive, so we need to shift them with daily
    // booking.
    return endDate.add(1, 'days').toDate();
  } else {
    return endDate.toDate();
  }
};

// Since final-form tracks the onBlur event for marking the field as
// touched (which triggers possible error validation rendering), only
// trigger the event asynchronously when no other input within this
// component has received focus.
//
// This prevents showing the validation error when the user selects a
// value and moves on to another input within this component.
const BLUR_TIMEOUT = 100;

const MIN_MONTHS = 1;
const MAX_MONTHS = 24;

const DEBOUNCE_TIME = 400;

// IconArrowHead component might not be defined if exposed directly to the file.
// This component is called before IconArrowHead component in components/index.js
const PrevIcon = props => (
  <IconArrowHead {...props} direction="left" rootClassName={css.arrowIcon} />
);
const NextIcon = props => (
  <IconArrowHead {...props} direction="right" rootClassName={css.arrowIcon} />
);

// Possible configuration options of React-dates
const defaultProps = {
  initialDates: null, // Possible initial date passed for the component
  value: null, // Value should keep track of selected date.

  // input related props
  startDateId: 'startDate',
  endDateId: 'endDate',
  startDatePlaceholderText: null, // Handled inside component
  endDatePlaceholderText: null, // Handled inside component
  disabled: false,
  required: false,
  readOnly: false,
  screenReaderInputMessage: null, // Handled inside component
  showClearDates: false,
  showDefaultInputIcon: false,
  customArrowIcon: <span />,
  customInputIcon: null,
  customCloseIcon: null,
  noBorder: true,
  block: false,

  // calendar presentation and interaction related props
  renderMonthText: null,
  orientation: HORIZONTAL_ORIENTATION,
  anchorDirection: ANCHOR_LEFT,
  horizontalMargin: 0,
  withPortal: false,
  withFullScreenPortal: false,
  appendToBody: false,
  disableScroll: false,
  daySize: 38,
  isRTL: false,
  initialVisibleMonth: null,
  // This gets default value at FieldDateRangeInput
  firstDayOfWeek: 0,
  numberOfMonths: 1,
  keepOpenOnDateSelect: false,
  reopenPickerOnClearDates: false,
  renderCalendarInfo: null,
  hideKeyboardShortcutsPanel: true,

  // navigation related props
  navPrev: <PrevIcon />,
  navNext: <NextIcon />,
  onPrevMonthClick() { },
  onNextMonthClick() { },
  transitionDuration: 200, // milliseconds between next month changes etc.

  renderCalendarDay: undefined, // If undefined, renders react-dates/lib/components/CalendarDay
  // day presentation and interaction related props
  renderDayContents: day => {
    return <span className="renderedDay">{day.format('D')}</span>;
  },
  minimumNights: 0,
  enableOutsideDays: false,
  isDayBlocked: () => () => false,

  // This gets default value at FieldDateRangeInput
  isOutsideRange: day => false,
  isDayHighlighted: () => { },

  // Internationalization props
  // Multilocale support can be achieved with displayFormat like moment.localeData.longDateFormat('L')
  // https://momentjs.com/
  displayFormat: 'ddd, MMM D',
  monthFormat: 'MMMM YYYY',
  weekDayFormat: 'dd',
};

class DateRangeInputComponent extends Component {
  constructor(props) {
    super(props);

    this.state = {
      focusedInput: null,
      focused: false,
      currentStartDate: null,
      unitsMonthly: 1,
      unitsDay: 1,
    };

    this.blurTimeoutId = null;
    this.onDatesChange = this.onDatesChange.bind(this);
    this.onSingleDateChange = this.onSingleDateChange.bind(this);
    this.onFocusChange = this.onFocusChange.bind(this);
    this.onFocusSingleChange = this.onFocusSingleChange.bind(this);
  }

  componentDidUpdate(prevProps) {
    // Update focusedInput in case a new value for it is
    // passed in the props. This may occur if the focus
    // is manually set to the date picker.
    if (this.props.focusedInput && this.props.focusedInput !== prevProps.focusedInput) {
      this.setState({ focusedInput: this.props.focusedInput });
    }
  }

  componentWillUnmount() {
    window.clearTimeout(this.blurTimeoutId);
  }

  onDatesChange(dates) {
    const { isDaily, isBlockedBetween } = this.props;
    const { startDate, endDate } = dates;

    // both dates are selected, a new start date before the previous start
    // date is selected
    const startDateUpdated =
      isBlockedBetween &&
      startDate &&
      endDate &&
      this.state.currentStartDate &&
      startDate.isBefore(this.state.currentStartDate);

    let startDateAsDate = startDate instanceof moment ? startDate.startOf('day').toDate() : null;
    let endDateAsDate = pickerEndDateToApiDate(isDaily, endDate);

    if (startDateUpdated) {
      // clear the end date in case a blocked date can be found
      // between previous start date and new start date
      const clearEndDate = isBlockedBetween(
        startDate,
        moment(this.state.currentStartDate).add(1, 'days')
      );
      endDateAsDate = clearEndDate ? null : pickerEndDateToApiDate(isDaily, endDate);
    }

    this.setState(() => ({
      currentStartDate: startDateAsDate,
    }));

    this.props.onChange({ startDate: startDateAsDate, endDate: endDateAsDate });
  }

  async onSingleDateChange(dates) {
    const startDate = dates;
    const { unitType, listing, isOwnListing, isAuthenticated } = this.props;
    const { unitsMonthly } = this.state;

    const startDateAsDate = startDate instanceof moment ? startDate.toDate() : null;
    const endDateAsDate = pickerEndDateToApiDate(
      unitType,
      moment(startDateAsDate).add(unitsMonthly, 'M'),
      unitsMonthly
    );

    if (!isOwnListing && isAuthenticated) {
      await this.props.fetchSpeculatedTransaction(
        {
          listingId: listing.id,
          bookingStart: startDateAsDate,
          bookingEnd: endDateAsDate,
          unitType: LINE_ITEM_DAY,
        },
        null
      );
    }

    // if (this.props.speculateTransactionError) {
    //   this.props.onChange({ startDate: null, endDate: null, unitType: LINE_ITEM_MONTH });
    // } else {
      this.setState(() => ({
        currentStartDate: startDateAsDate,
        focusedInput: { focused: false },
      }));
      this.props.onChange({
        startDate: startDateAsDate,
        endDate: endDateAsDate,
        unitType: LINE_ITEM_MONTH,
      });
    // }
  }

  onFocusChange(focusedInput) {
    // DateRangePicker requires 'onFocusChange' function and 'focusedInput'
    // but Fields of React-Form deals with onFocus & onBlur instead
    this.setState({ focusedInput });

    if (focusedInput) {
      window.clearTimeout(this.blurTimeoutId);
      this.props.onFocus(focusedInput);
    } else {
      window.clearTimeout(this.blurTimeoutId);
      this.blurTimeoutId = window.setTimeout(() => {
        this.props.onBlur();
      }, BLUR_TIMEOUT);
    }
  }

  onFocusSingleChange(focusedInput) {
    // DateRangePicker requires 'onFocusChange' function and 'focusedInput'
    // but Fields of React-Form deals with onFocus & onBlur instead
    this.setState({ focusedInput });

    if (focusedInput.focused) {
      this.props.onFocus();
    } else {
      // this.props.onBlur();
      // this.setState({ focusedInput: false });
    }
  }

  updateUnitsMonthly(e) {
    const value = e.target.value;
    if (!value) {
      this.setState({ unitsMonthly: value });
    } else if (value <= MIN_MONTHS - 1) {
      e.preventDefault();
    } else if (value >= MAX_MONTHS + 1) {
      e.preventDefault();
    } else {
      this.setState({ unitsMonthly: value }, () => {
        const { currentStartDate } = this.state;
        if (currentStartDate) this.onSingleDateChange(moment(currentStartDate));
      });
    }
  }

  decrementUnit() {
    const { unitsMonthly, currentStartDate } = this.state;
    if (unitsMonthly > MIN_MONTHS) {
      this.setState(
        {
          unitsMonthly: parseInt(unitsMonthly) - 1,
        },
        () => {
          if (currentStartDate) this.onSingleDateChange(moment(currentStartDate));
        }
      );
    }
  }

  incrementUnit() {
    const { unitsMonthly, currentStartDate } = this.state;
    if (unitsMonthly < MAX_MONTHS) {
      this.setState(
        {
          unitsMonthly: parseInt(unitsMonthly) + 1,
        },
        () => {
          if (currentStartDate) this.onSingleDateChange(moment(currentStartDate));
        }
      );
    }
  }

  onUpdateUnitType(unitType) {
    const now = moment();
    const newStartDate = now.startOf('day').toDate();

    let newEndDate;
    if (unitType === LINE_ITEM_DAY) {
      const { unitsDay } = this.state;
      const newUnitsDay = unitsDay ? unitsDay : 1;
      newEndDate = now
        .startOf('day')
        .add(unitsDay, 'days')
        .toDate();
      this.setState({ unitsDay: newUnitsDay });
    } else {
      const { unitsMonthly } = this.state;
      const newUnitsMonthly = unitsMonthly ? unitsMonthly : 1;
      newEndDate = now
        .startOf('day')
        .add(newUnitsMonthly, 'M')
        .toDate();
      this.setState({ unitsMonthly: newUnitsMonthly });
    }

    this.setState({ focusedInput: null });

    this.props.onUpdateUnitType(unitType);
    this.props.onChange({ startDate: newStartDate, endDate: newEndDate, unitType: unitType });
  }

  render() {
    const {
      className,
      isDaily,
      initialDates,
      intl,
      name,
      startDatePlaceholderText,
      endDatePlaceholderText,
      onBlur,
      onChange,
      onFocus,
      screenReaderInputMessage,
      useMobileMargins,
      value,
      children,
      render,
      isBlockedBetween,
      isDayBlocked,
      isOutsideRange,
      label,
      listing,
      selectedUnitType,
      phrases,
      ...datePickerProps
    } = this.props;

    const initialStartMoment = initialDates ? moment(initialDates.startDate) : null;
    const initialEndMoment = initialDates ? moment(initialDates.endDate) : null;
    const startDate =
      value && value.startDate instanceof Date ? moment(value.startDate) : initialStartMoment;
    const endDate =
      apiEndDateToPickerDate(isDaily, value ? value.endDate : null) || initialEndMoment;

    const startDatePlaceholderTxt =
      startDatePlaceholderText ||
      intl.formatMessage({ id: 'FieldDateRangeInput.startDatePlaceholderText' });
    const endDatePlaceholderTxt =
      endDatePlaceholderText ||
      intl.formatMessage({ id: 'FieldDateRangeInput.endDatePlaceholderText' });
    const screenReaderInputText =
      screenReaderInputMessage ||
      intl.formatMessage({ id: 'FieldDateRangeInput.screenReaderInputMessage' });
    const closeDatePickerText = 'Close datepicker' || intl.formatMessage({ id: 'FieldDateRangeInput.closeDatePicker' });
    const clearDateText = intl.formatMessage({ id: 'FieldDateRangeInput.clearDate' });

    const { unitsMonthly } = this.state;

    const classes = classNames(css.inputRoot, className, {
      [css.withMobileMargins]: useMobileMargins,
    });

    // const priceDailyAvailable = listing?.attributes?.publicData?.priceDaily ? true : false;

    return (
      <>
        {/* {priceDailyAvailable && (
          <>
            <div className={css.btnGroup}>
              <button
                type="button"
                className={selectedUnitType === LINE_ITEM_MONTH ? css.active : ''}
                onClick={() => this.onUpdateUnitType(LINE_ITEM_MONTH)}
              >
                Per Month
              </button>
              <button
                type="button"
                className={selectedUnitType === LINE_ITEM_DAY ? css.active : ''}
                onClick={() => this.onUpdateUnitType(LINE_ITEM_DAY)}
              >
                Per Day
              </button>
            </div>
            <br />
          </>
        )} */}
        {/* {selectedUnitType === LINE_ITEM_MONTH && ( */}
          <div className={css.inputUnitsContainer}>
            <label className={css.enterUnitsLabel}>
              <FormattedMessage id="DateRangeInput.enterMonthly" />
            </label>
            <div className={css.inputUnitsGroup}>
              <button
                type="button"
                className={unitsMonthly <= MIN_MONTHS ? css.inactive : ''}
                onClick={() => debounce(this.decrementUnit(), DEBOUNCE_TIME)}
              >
                -
              </button>
              <input
                id={css.unitsMonthly}
                className={css.bookingDates}
                name="unitsMonthly"
                type="number"
                min={MIN_MONTHS}
                max={MAX_MONTHS}
                value={unitsMonthly}
                onChange={e => debounce(this.updateUnitsMonthly(e), DEBOUNCE_TIME)}
              />
              <button
                type="button"
                className={unitsMonthly >= MAX_MONTHS ? css.inactive : ''}
                onClick={() => debounce(this.incrementUnit(), DEBOUNCE_TIME)}
              >
                +
              </button>
            </div>
          </div>
        {/* )} */}
        <br />
        {label}
        {/* {selectedUnitType === LINE_ITEM_DAY ? (
          <div className={classes}> */}
            {/* <DateRangePicker
              {...datePickerProps}
              isDayBlocked={isDayBlocked(this.state.focusedInput)}
              isOutsideRange={isOutsideRange(this.state.focusedInput)}
              focusedInput={this.state.focusedInput}
              onFocusChange={this.onFocusChange}
              startDate={startDate}
              endDate={endDate}
              minimumNights={isDaily ? 0 : 1}
              onDatesChange={this.onDatesChange}
              startDatePlaceholderText={startDatePlaceholderTxt}
              endDatePlaceholderText={endDatePlaceholderTxt}
              screenReaderInputMessage={screenReaderInputText}
            /> */}
            {/* <DateRangePicker
              {...datePickerProps}
              focusedInput={this.state.focusedInput}
              onFocusChange={this.onFocusChange}
              startDate={startDate}
              endDate={endDate}
              minimumNights={isDaily ? 0 : 1}
              onDatesChange={this.onDatesChange}
              startDatePlaceholderText={startDatePlaceholderTxt}
              endDatePlaceholderText={endDatePlaceholderTxt}
              screenReaderInputMessage={screenReaderInputText}
              phrases={{ closeDatePicker: closeDatePickerText, clearDate: clearDateText }}
              isDayBlocked={isDayBlocked}
              isOutsideRange={isOutsideRange}
            /> */}
          {/* </div>
        ) : ( */}
          <div className={classes}>
            <SingleDatePicker
              {...datePickerProps}
              displayFormat={'ddd, MMM D, Y'}
              focused={this.state.focusedInput?.focused}
              onFocusChange={this.onFocusSingleChange}
              date={startDate}
              onDateChange={this.onSingleDateChange}
              placeholder={startDatePlaceholderTxt}
              screenReaderInputMessage={screenReaderInputText}
              // isDayBlocked={isDayBlocked}
              // isOutsideRange={isOutsideRange}
              phrases={{ closeDatePicker: closeDatePickerText, clearDate: clearDateText }}
            />
          </div>
        {/* )} */}
      </>
    );
  }
}

const mapDispatchToProps = dispatch => ({
  fetchSpeculatedTransaction: (params, transactionId) =>
    dispatch(speculateTransaction(params, transactionId)),
});

const mapStateToProps = state => {
  const { speculateTransactionError } = state.CheckoutPage;
  const { isAuthenticated } = state.auth;
  console.log(`state`, state)
  return {
    speculateTransactionError,
    isAuthenticated,
  };
};


DateRangeInputComponent.defaultProps = {
  className: null,
  useMobileMargins: false,
  ...defaultProps,
  isOwnListing: false,
  listing: null,
  onUpdateUnitType: null,
  selectedUnitType: LINE_ITEM_MONTH,
};

DateRangeInputComponent.propTypes = {
  className: string,
  startDateId: string,
  endDateId: string,
  isDaily: bool.isRequired,
  focusedInput: oneOf([START_DATE, END_DATE]),
  initialDates: instanceOf(Date),
  name: string.isRequired,
  isBlockedBetween: func,
  isDayBlocked: func,
  isOutsideRange: func,
  onChange: func.isRequired,
  onBlur: func.isRequired,
  onFocus: func.isRequired,
  useMobileMargins: bool,
  startDatePlaceholderText: string,
  endDatePlaceholderText: string,
  screenReaderInputMessage: string,
  value: shape({
    startDate: instanceOf(Date),
    endDate: instanceOf(Date),
  }),

  // from injectIntl
  intl: intlShape.isRequired,
  listing: any,
  onUpdateUnitType: func.isRequired,
  selectedUnitType: string.isRequired,
};

const DateRangeInput = compose(
  connect(mapStateToProps, mapDispatchToProps),
  injectIntl
)(DateRangeInputComponent);

export default DateRangeInput;
