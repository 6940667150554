import React from 'react';
import { FormattedMessage, intlShape } from '../../util/reactIntl';
import { formatMoney } from '../../util/currency';
import { LINE_ITEM_NIGHT, LINE_ITEM_DAY, propTypes, LINE_ITEM_HOUR, LINE_ITEM_MONTH } from '../../util/types';

import css from './OrderBreakdown.module.css';

const LineItemBasePriceMaybe = props => {
  const { lineItems, code, intl } = props;
  // const isNightly = code === LINE_ITEM_NIGHT;
  // const isDaily = code === LINE_ITEM_DAY;
  // const isMonthly = code === LINE_ITEM_MONTH;
  // const isHourly = code === LINE_ITEM_HOUR;
  
  // const translationKey = isNightly
  //   ? 'OrderBreakdown.baseUnitNight'
  //   : isDaily
  //   ? 'OrderBreakdown.baseUnitDay'
  //   : isMonthly
  //   ? 'OrderBreakdown.baseUnitMonth'
  //   : isHourly
  //   ? 'OrderBreakdown.baseUnitHour'
  //   : 'OrderBreakdown.baseUnitQuantity';

  let translationKey;
  switch (code) {
    case LINE_ITEM_NIGHT:
      translationKey = 'OrderBreakdown.baseUnitNight';
      break;
    case LINE_ITEM_DAY:
      translationKey = 'OrderBreakdown.baseUnitDay';
      break;
    case LINE_ITEM_MONTH:
      translationKey = 'OrderBreakdown.baseUnitMonth';
      break;
    default:
      translationKey = 'OrderBreakdown.baseUnitQuantity';
      break;
  }

  // Find correct line-item for given code prop.
  // It should be one of the following: 'line-item/night, 'line-item/day', 'line-item/hour', or 'line-item/item'
  // These are defined in '../../util/types';
  const unitPurchase = lineItems.find(item => item.code === code && !item.reversal);

  const quantity = unitPurchase ? unitPurchase.quantity.toString() : null;
  console.log(`quantity`, quantity)
  const unitPrice = unitPurchase ? formatMoney(intl, unitPurchase.unitPrice) : null;
  const total = unitPurchase ? formatMoney(intl, unitPurchase.lineTotal) : null;

  return quantity && total ? (
    <div className={css.lineItem}>
      <span className={css.itemLabel}>
        <FormattedMessage id={translationKey} values={{ unitPrice, quantity }} />
      </span>
      <span className={css.itemValue}>{total}</span>
    </div>
  ) : null;
};

LineItemBasePriceMaybe.propTypes = {
  lineItems: propTypes.lineItems.isRequired,
  code: propTypes.lineItemUnitType.isRequired,
  intl: intlShape.isRequired,
};

export default LineItemBasePriceMaybe;
